import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

export default function configureSessionReplay() {
  if (import.meta.env.REACT_APP_SESSION_REPLAY_ENABLED === 'true') {
    let lrAppName =
      import.meta.env.REACT_APP_SESSION_REPLAY_NAME || 'envoc/unknown_app';
    LogRocket.init(lrAppName);
    setupLogRocketReact(LogRocket);
  }
}
