import axios from 'axios';
import { AccountService } from 'api';

const noViewerResult = {
  result: null,
  locationId: null,
  validationFailures: [],
  createdEntities: [],
  hasErrors: false,
};

export function getSignedInViewerDetailsOrDefault(
  ...params: Parameters<typeof AccountService.getSignedInViewerDetails>
) {
  return AccountService.getSignedInViewerDetails(...params).catch((error) => {
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      return Object.assign({}, noViewerResult);
    }
    throw error;
  });
}
