import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createStrictContext } from 'envoc-strict-context';
import {
  ViewerCameraSessionService,
  WebViewViewerSignInSessionService,
} from 'api';
import { useLocalStorage } from 'shared/hooks';
import {
  authActions,
  authSelectors,
  useReduxDispatch,
  useReduxSelector,
} from 'shared/state';

const LAST_SESSION_CREATED_MS_KEY = 'LAST_SESSION_CREATED_MS';
const LAST_SESSION_USERID_KEY = 'LAST_SESSION_USERID';
const SIGN_IN_SESSION_GUID_KEY = 'SIGN_IN_SESSION_GUID';

type SessionStatus = 'loading' | 'idle' | 'ongoing' | 'ended';

const [ContextProvider, useSessionContext] = createStrictContext<{
  /**
   * The last camera session created, in milliseconds.
   */
  lastSessionCreatedMs: number | null;
  /**
   * The max length of a camera session for this user, in milliseconds.
   */
  sessionMaxLengthMs: number | null;
  /**
   * Gets the time left before the session ends, in milliseconds.
   */
  getTimeLeftMs: () => number;
  /**
   * Attempts to start a new camera session.
   */
  startSession: (overrideSessionMaxCount?: boolean) => Promise<void>;
  /**
   * Clears data about the last session so that a new session can begin.
   */
  clearSession: () => void;
  /**
   * **loading** - Retrieving user info to determine the camera session status.
   *
   * **idle** - There is no ongoing session.
   *
   * **ongoing** - There is an ongoing session.
   *
   * **ended** - The last session has ended and the user has not logged out yet.
   */
  sessionStatus: SessionStatus;
  /**
   * Sets whether or not the user is logging in,
   * which will affect what happens when the user information loads.
   */
  setLoggingIn: (val: boolean) => void;
}>({ name: 'Session' });

function SessionContext({ children }: React.PropsWithChildren) {
  const { user } = useReduxSelector(authSelectors.getAuth);
  const dispatch = useReduxDispatch();
  const [loggingIn, setLoggingIn] = useState(false);

  const [lastSessionCreatedMs, setLastSessionCreatedMs] = useLocalStorage(
    LAST_SESSION_CREATED_MS_KEY,
    Number
  );
  const [lastSessionUserId, setLastSessionUserId] = useLocalStorage(
    LAST_SESSION_USERID_KEY,
    Number
  );

  const [signInSessionGuid, setSignInSessionGuid] = useLocalStorage(
    SIGN_IN_SESSION_GUID_KEY
  );

  const startingSession = useRef(false);

  const startSession = useCallback(
    async function startSession(overrideSessionMaxCount?: boolean) {
      const userId = user?.id;
      if (startingSession.current) {
        return;
      }
      if (userId === undefined) {
        throw new Error('No user to start a session for');
      }
      if (
        !overrideSessionMaxCount &&
        ((user?.viewerCameraSessionMaxCount &&
          (user.sessionCount ?? 0) >= user.viewerCameraSessionMaxCount) ||
          user?.viewerCameraSessionMaxCount === 0)
      ) {
        throw new Error('Session limit exceeded');
      }
      startingSession.current = true;
      await ViewerCameraSessionService.create1();
      setLastSessionCreatedMs(Date.now());
      setLastSessionUserId(userId);
      startingSession.current = false;
      dispatch(authActions.loadSessionInfo());
    },
    [
      dispatch,
      setLastSessionCreatedMs,
      setLastSessionUserId,
      user?.id,
      user?.sessionCount,
      user?.viewerCameraSessionMaxCount,
    ]
  );

  const clearSession = useCallback(
    function clearSession() {
      setLastSessionCreatedMs(null);
      setLastSessionUserId(null);
    },
    [setLastSessionCreatedMs, setLastSessionUserId]
  );

  const getTimeLeftMs = useCallback(
    function getTimeLeftMs() {
      if (
        !(
          user?.viewerCameraSessionMaxLengthInMinutes !== undefined &&
          lastSessionCreatedMs !== null
        )
      ) {
        return 0;
      }
      return Math.max(
        0,
        user.viewerCameraSessionMaxLengthInMinutes * 60 * 1000 -
          (Date.now() - lastSessionCreatedMs)
      );
    },
    [lastSessionCreatedMs, user?.viewerCameraSessionMaxLengthInMinutes]
  );

  const [sessionStatus, setSessionStatus] = useState<SessionStatus>('loading');

  // set session status
  useEffect(() => {
    if (user?.viewerCameraSessionMaxLengthInMinutes === undefined) {
      setSessionStatus('loading');
    } else if (lastSessionCreatedMs === null) {
      setSessionStatus('idle');
    } else {
      const timeLeft = getTimeLeftMs();
      if (timeLeft > 0) {
        setSessionStatus('ongoing');
        const endTimer = setTimeout(() => setSessionStatus('ended'), timeLeft);
        return () => {
          clearTimeout(endTimer);
        };
      } else {
        setSessionStatus('ended');
      }
    }
  }, [
    getTimeLeftMs,
    lastSessionCreatedMs,
    user?.viewerCameraSessionMaxLengthInMinutes,
  ]);

  // if you login as a different user the session ends
  if (
    user?.id !== undefined &&
    lastSessionUserId !== null &&
    user.id !== lastSessionUserId
  ) {
    clearSession();
  }

  // on login
  if (loggingIn && user) {
    handleLogin();
  }

  const sessionMaxLengthMs =
    user?.viewerCameraSessionMaxLengthInMinutes !== undefined
      ? user.viewerCameraSessionMaxLengthInMinutes * 60 * 1000
      : null;

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (user?.id) {
      WebViewViewerSignInSessionService.createOrUpdate({
        body: { userId: user.id, sessionId: signInSessionGuid ?? undefined },
      }).then((resp) => {
        if (resp.result !== signInSessionGuid) {
          setSignInSessionGuid(resp.result ?? null);
        } else {
          interval = setInterval(() => {
            WebViewViewerSignInSessionService.createOrUpdate({
              body: { userId: user.id, sessionId: signInSessionGuid },
            }).then((resp) => {
              setSignInSessionGuid(resp.result ?? null);
            });
          }, 30000);
        }
      });
    }
    return () => {
      clearInterval(interval);
    };
  }, [setSignInSessionGuid, signInSessionGuid, user?.id]);

  const value = useMemo(() => {
    return {
      lastSessionCreatedMs,
      sessionMaxLengthMs,
      getTimeLeftMs,
      startSession,
      clearSession,
      sessionStatus,
      setLoggingIn,
    };
  }, [
    clearSession,
    getTimeLeftMs,
    lastSessionCreatedMs,
    sessionMaxLengthMs,
    sessionStatus,
    startSession,
  ]);

  return <ContextProvider value={value}>{children}</ContextProvider>;

  function handleLogin() {
    if (getTimeLeftMs() <= 0) {
      clearSession();
    }
    setLoggingIn(false);
  }
}

export { useSessionContext, SessionContext, LAST_SESSION_CREATED_MS_KEY };
