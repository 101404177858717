type REACT_APP_ENVIRONMENT = 'Local' | 'Dev' | 'QA' | 'Stage' | 'Production';

export type EnvType = {
  isProductionBuild: boolean;
  name: REACT_APP_ENVIRONMENT;
};

export const Env: EnvType = {
  isProductionBuild: import.meta.env.NODE_ENV === 'production',
  name: import.meta.env.VITE_REACT_APP_ENVIRONMENT as REACT_APP_ENVIRONMENT,
};
