import { useEffect, useState } from 'react';
import { responsive } from 'shared/framework';
import { Breakpoints } from 'shared/framework/responsive';

type BreakpointFlags = {
  [Property in keyof Breakpoints]: boolean;
};

interface BreakpointChecks extends BreakpointFlags {
  forceLandscape: boolean;
}

const breakpointSubs = new Set<(x: BreakpointChecks) => void>();

let responsiveInfo = {} as BreakpointChecks;

function calculate() {
  const width = responsive.width();
  const newInfo = {} as BreakpointChecks;
  let shouldUpdate = false;

  for (const key of Object.keys(responsive.breakpoints) as Array<
    keyof BreakpointFlags
  >) {
    newInfo[key] = width >= responsive.breakpoints[key];
    if (newInfo[key] !== responsiveInfo[key]) {
      shouldUpdate = true;
    }
  }
  const forceLandscape = responsive.forceLandscape();
  if (responsiveInfo.forceLandscape !== forceLandscape) {
    shouldUpdate = true;
    newInfo.forceLandscape = forceLandscape;
  }
  if (shouldUpdate) {
    responsiveInfo = newInfo;
  }
}

calculate();

window.addEventListener('resize', () => {
  const oldInfo = responsiveInfo;
  calculate();
  if (oldInfo === responsiveInfo) {
    return;
  }
  for (const subscriber of breakpointSubs) {
    subscriber(responsiveInfo);
  }
});

export default function useResponsive() {
  const [responsiveState, setResponsiveState] = useState(responsiveInfo);

  useEffect(() => {
    breakpointSubs.add(onWindowResize);
    return () => {
      breakpointSubs.delete(onWindowResize);
    };
    function onWindowResize(x: BreakpointChecks) {
      setResponsiveState(x);
    }
  }, []);
  return responsiveState;
}
