import React, { ReactNode, Suspense, useMemo } from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import App from 'App';
import routeFiles from 'route-files';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigationPathConverter } from 'shared/utils';
import { MatchedLeafContextProvider } from './MatchedLeafContextProvider';
import { RouteObjectWithParent } from './types';

/**
 * Creates a tree of routes based on the file and folder layout with src/routes
 * Each file in any folder at or below src/routes should render a default component that will be rendered when matching
 * The path to that component in the filesystem will represent the
 */
export default function FileSystemRoutes() {
  const result = useMemo(() => {
    const rootRoutes = [] as RouteObjectWithParent[];
    let RootLayout = null as ReactNode | null;

    for (let index = 0; index < routeFiles.length; index++) {
      const element = routeFiles[index];
      const Component = React.lazy(element.importFunc as any);

      let parent = null as RouteObjectWithParent | null;

      // take each part in a path and ensure we have a route for each name
      // this way we can handle folder routes that have no layout
      const parts = element.path.split('/');
      for (let index = 0; index < parts.length; index++) {
        const part = parts[index];

        const effectivePath = NavigationPathConverter(part);

        // special note: we assume no folder will ever be called _Layout
        if (part === '_Layout') {
          // this should wrap all the children, including index routes - there should exist only a single _Layout instance per path part (e.g. folder)
          const LayoutElement = (
            <Suspense fallback={<BlockingLoader />}>
              <Component />
            </Suspense>
          );

          if (parent === null) {
            RootLayout = LayoutElement;
          } else {
            parent.element = LayoutElement;
          }
        } else if (index === parts.length - 1) {
          const leafConfig = {
            parent: parent,
          } as RouteObjectWithParent;

          if (effectivePath === 'index') {
            leafConfig.fullPath = (parent?.fullPath ?? '') + '/';
            leafConfig.index = true;
          } else if (effectivePath === '404') {
            leafConfig.fullPath = (parent?.fullPath ?? '') + '/NotFound';
            // only the last character may be '*' in react router 6
            // see: https://reactrouter.com/docs/en/v6/upgrading/v5#note-on-route-path-patterns
            leafConfig.path = '*';
          } else {
            leafConfig.fullPath =
              (parent?.fullPath ?? '') + '/' + effectivePath.replace('~', '');
            // we allow any Leaf element to render any child routes they wish
            // The only downside is that in such a case we cannot show a 404 page on a partial match
            // e.g. if we have a /Foo component
            leafConfig.path =
              effectivePath === '*' ? '*' : effectivePath.replace('~', '/*');
          }

          // this is the final element in question in the path
          const Leaf = (
            <MatchedLeafContextProvider value={leafConfig}>
              <Suspense fallback={<BlockingLoader isFullScreen={false} />}>
                <Component />
              </Suspense>
            </MatchedLeafContextProvider>
          );
          leafConfig.element = Leaf;

          if (parent === null) {
            rootRoutes.push(leafConfig);
          } else {
            if (!parent.children) {
              parent.children = [];
            }
            parent.children.push(leafConfig);
          }
        } else {
          // we still have folders to parse, keep going deeper
          let parentPool = [] as RouteObjectWithParent[];
          if (!parent) {
            parentPool = rootRoutes;
          } else {
            if (!parent.children) {
              parent.children = [];
            }
            parentPool = parent.children as RouteObjectWithParent[];
          }
          const existingParent = parentPool.find(
            (x) => x.path === effectivePath
          );
          if (!existingParent) {
            const parentConfig = {
              parent: parent,
              fullPath: (parent?.fullPath ?? '') + '/' + effectivePath,
              path: effectivePath,
              element: <Outlet />,
            } as RouteObjectWithParent;
            parentPool.push(parentConfig);
            parent = parentConfig;
          } else {
            parent = existingParent;
          }
        }
      }
    }
    if (!RootLayout) {
      return createBrowserRouter(
        [
          {
            path: '/',
            element: <App />,
            children: rootRoutes,
          },
        ],
        {
          basename: import.meta.env.PUBLIC_URL,
        }
      );
    }

    return createBrowserRouter(
      [
        {
          path: '/',
          element: <App />,
          children: [
            {
              path: '/',
              element: RootLayout,
              children: rootRoutes,
            },
          ],
        },
      ],
      {
        basename: import.meta.env.PUBLIC_URL,
      }
    );
  }, []);

  return <RouterProvider router={result} />;
}

function BlockingLoader({ isFullScreen = true }) {
  const sizeClasses = isFullScreen
    ? 'fixed w-screen h-screen'
    : 'w-full h-full';
  return (
    <>
      <div
        className={`top-0 left-0 z-50 flex flex-col items-center justify-center ${sizeClasses} gap-2 text-gray-600 bg-white`}>
        <div>Loading Content</div>
        <FontAwesomeIcon
          icon={faSpinner}
          className="!w-12 !h-12 animate-spin"
        />
      </div>
      <div className="hidden">
        <Outlet />
      </div>
    </>
  );
}
