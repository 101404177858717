import 'envoc-table/dist/css/envoc-table-styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/index.css';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import FileSystemRoutes from 'shared/framework/routing/FileSystemRoutes';
import { store } from 'shared/state';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <FileSystemRoutes />
  </Provider>
);
