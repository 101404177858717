import { ReactNode } from 'react';
import { useQuery } from 'react-query';
import { createStrictContext } from 'envoc-strict-context';
import { FeatureFlagType, FeaturesService } from 'api';
import { authSelectors, useReduxSelector } from 'shared/state';

export interface FeatureFlagContextValue {
  isSupported: (flag: FeatureFlagType | FeatureFlagType[]) => boolean;
  loading: boolean;
}

const [ContextProvider, useFeatureFlagContext] =
  createStrictContext<FeatureFlagContextValue>({
    name: 'FeatureFlagContext',
  });

function FeatureFlagProvider({ children }: { children: ReactNode }) {
  const user = useReduxSelector(authSelectors.getUser);

  const { data, isLoading } = useQuery(
    ['feature-flags', user?.id ?? 0],
    () => FeaturesService.getFeaturesAsViewer(),
    {
      enabled: !!user,
    }
  );

  function isSupported(flag: FeatureFlagType | FeatureFlagType[]) {
    return typeof flag === 'object'
      ? flag.every((item) => data?.result?.some((f) => f === item))
      : data?.result?.some((f) => f === flag) ?? false;
  }

  const loading = isLoading || !user;

  return (
    <ContextProvider value={{ isSupported, loading }}>
      {children}
    </ContextProvider>
  );
}

export { FeatureFlagProvider, useFeatureFlagContext };
