import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

const showDelayMs = 500; // min loading time before a toast is shown
const hideDelayMs = 1000; // min time that a toast is shown

function showLoadingToast() {
  return toast.info(`Loading...`, {
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
    position: toast.POSITION.BOTTOM_CENTER,
  });
}

export default function DelayedLoadingToast({ loading }: { loading: Boolean }) {
  const activeToastRef = useRef<React.ReactText | null>(null);
  const showTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const hideTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    return () => {
      // remove everything if we are getting unmounted
      if (hideTimeoutRef.current) {
        clearTimeout(hideTimeoutRef.current);
        hideTimeoutRef.current = null;
      }
      if (showTimeoutRef.current) {
        clearTimeout(showTimeoutRef.current);
        showTimeoutRef.current = null;
      }
      if (activeToastRef.current) {
        toast.dismiss(activeToastRef.current);
        activeToastRef.current = null;
      }
    };
  }, []);

  if (loading && !activeToastRef.current && !showTimeoutRef.current) {
    // delay showing by showDelayMs
    showTimeoutRef.current = setTimeout(() => {
      activeToastRef.current = showLoadingToast();
    }, showDelayMs);
  }

  if (!loading && activeToastRef.current && !hideTimeoutRef.current) {
    // delay hiding by hideDelayMs - so it doesn't 'flicker' too much
    hideTimeoutRef.current = setTimeout(() => {
      if (activeToastRef.current) {
        toast.dismiss(activeToastRef.current);
      }
      activeToastRef.current = null;
    }, hideDelayMs);
  }

  if (!loading && showTimeoutRef.current) {
    // if we are not loading and theres a pending SHOW - get rid of that pending SHOW request
    clearTimeout(showTimeoutRef.current);
    showTimeoutRef.current = null;
  }

  if (loading && hideTimeoutRef.current) {
    // if we ARE loading and theres a pending HIDE - get rid of that pending HIDE request
    clearTimeout(hideTimeoutRef.current);
    hideTimeoutRef.current = null;
  }

  return null;
}
