import { createStrictContext } from 'envoc-strict-context';
import { RouteObjectWithParent } from './types';

/**
 * The useLeafContext hook allows access to the route config such that a leaf element can fetch it's own config or any of its progenitors
 * @returns The matched MatchedLeafContext from FileSystemRoutes
 * @throws If not used within the context of a FileSystemRoutes
 */
export const [MatchedLeafContextProvider, useLeafContext] =
  createStrictContext<RouteObjectWithParent>({
    errorMessage:
      'Missing MatchedLeafContext - this should only be used within the context of a FileSystemRoutes component',
    name: 'MatchedLeafContext',
  });
