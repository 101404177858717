import { ForwardedRef, forwardRef } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import StrongLink, { StrongLinkProps } from './StrongLink';
import StyledElementButtonOutline, {
  StyledElementButtonOutlineColors,
} from './StyledElementButtonOutline';

interface LinkOutlineButtonPropList {
  color: StyledElementButtonOutlineColors;
}

type StrongLinkOutlineButtonProps = StrongLinkProps & LinkOutlineButtonPropList;

type WeakLinkOutlineButtonProps = {
  unsafe: true;
} & LinkProps &
  LinkOutlineButtonPropList;

export type LinkOutlineButtonProps =
  | StrongLinkOutlineButtonProps
  | WeakLinkOutlineButtonProps;

/** A link that looks like an outline button */
const LinkOutlineButtonRef = forwardRef(LinkOutlineButton) as (
  // We are using a type assertion here to make the type more clear rather than exotic components and such
  // see: https://fettblog.eu/typescript-react-generic-forward-refs/#option-1%3A-type-assertion
  {
    className,
    color,
    ...props
  }: LinkOutlineButtonProps & React.RefAttributes<HTMLAnchorElement>
) => ReturnType<typeof LinkOutlineButton>;

export default LinkOutlineButtonRef;

function LinkOutlineButton(
  { color, ...props }: LinkOutlineButtonProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  if ('unsafe' in props) {
    const { unsafe, ...rest } = props;
    return (
      <StyledElementButtonOutline
        {...rest}
        tag={Link}
        color={color}
        forwardRef={ref}
      />
    );
  } else {
    return (
      <StyledElementButtonOutline
        {...props}
        tag={StrongLink}
        color={color}
        forwardRef={ref}
      />
    );
  }
}
