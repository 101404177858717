import { clearAuthToken, getSignedInViewerDetailsOrDefault } from 'auth';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AuthenticationService } from 'api';

declare module 'axios' {
  export interface AxiosRequestConfig {
    hasLoadingMessage?: boolean;
  }
}

export const loadUser = createAsyncThunk('auth/loadUser', async () => {
  try {
    const { result } = await getSignedInViewerDetailsOrDefault({
      hasLoadingMessage: true,
    });
    return result;
  } catch (error) {
    return null;
  }
});

export const loadSessionInfo = createAsyncThunk(
  'auth/loadSessionInfo',
  async () => {
    try {
      const { result } = await getSignedInViewerDetailsOrDefault({
        hasLoadingMessage: true,
      });
      if (result) {
        const {
          sessionCount,
          viewerCameraSessionMaxCount,
          viewerCameraSessionMaxLengthInMinutes,
          nextEvent,
        } = result;
        return {
          sessionCount,
          viewerCameraSessionMaxCount,
          viewerCameraSessionMaxLengthInMinutes,
          nextEvent,
        };
      }
      return null;
    } catch (error) {
      return null;
    }
  }
);

export const logout = createAsyncThunk('auth/logout', async () => {
  await AuthenticationService.signOut({
    hasLoadingMessage: true,
  });
  clearAuthToken();
  // need to re-get a new Xsrf - they are tied to user
  await getSignedInViewerDetailsOrDefault({ hasLoadingMessage: true });
});

export const setNoAuth = createAction('auth/setNoAuth');

export const allowRedirects = createAction('auth/allowRedirects');
