import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer';

const createStore = () => {
  const store = configureStore({
    reducer: reducer,
  });

  //TODO: not quite working? (it wasn't working before vite anyway)
  if (import.meta.env.NODE_ENV === 'development' && import.meta.hot) {
    import.meta.hot.accept('./reducer', () => {
      store.replaceReducer(reducer);
    });
  }

  return store;
};

const store = createStore();

export { store, createStore };

//notes:
//refactor return above
//move types as needed
//dev environment check above (needed?)
