import React, { ComponentProps, ElementType, ReactHTML } from 'react';
import classNames from 'classnames';

export type StyledElementButtonOutlineColors = 'red' | 'indigo';

export interface StyledElementButtonOutlineProps<
  T extends ElementType | keyof ReactHTML
> {
  tag: T;
  forwardRef: ComponentProps<T>['ref'];
  className?: string;
  color: StyledElementButtonOutlineColors;
}

/** styles a tag to look just like an outline button via className */
export default function StyledElementButtonOutline<
  T extends ElementType | keyof ReactHTML
>({
  tag,
  forwardRef,
  className,
  color,
  ...props
}: StyledElementButtonOutlineProps<T>) {
  return React.createElement(tag, {
    ...props,
    ref: forwardRef,
    className: classNames(
      className,
      'appearance-none flex justify-center items-center text-center px-4 py-2 text-sm font-medium border rounded-md shadow-sm',
      'focus:outline-none focus:ring-2 focus:ring-offset-2',
      'disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500',
      {
        'text-indigo-500 border-indigo-300 ring-indigo-200 hover:bg-indigo-100 hover:text-indigo-700':
          color === 'indigo',
        'text-red-500 border-red-300 ring-red-200 hover:bg-red-50 hover:text-red-700':
          color === 'red',
      }
    ),
  }) as JSX.Element;
}
