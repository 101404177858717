import React, { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react';
import StyledElementButtonOutline, {
  StyledElementButtonOutlineColors,
} from './StyledElementButtonOutline';

export interface ButtonOutlineProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  color: StyledElementButtonOutlineColors;
}

/** A button that has a color focused on the border/outline */
const ButtonOutline = forwardRef(ButtonOutlineBase) as (
  // We are using a type assertion here to make the type more clear rather than exotic components and such
  // see: https://fettblog.eu/typescript-react-generic-forward-refs/#option-1%3A-type-assertion
  {
    className,
    color,
    ...props
  }: ButtonOutlineProps & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof ButtonOutlineBase>;

export { ButtonOutline };

function ButtonOutlineBase(
  { color, ...props }: ButtonOutlineProps,
  ref: ForwardedRef<HTMLButtonElement>
) {
  return (
    <StyledElementButtonOutline
      {...props}
      tag="button"
      color={color}
      forwardRef={ref}
    />
  );
}
