export interface Breakpoints {
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xl2: number; //note, tailwind uses 2xl, but we want javascript friendly names here
}

const breakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xl2: 1536,
} as Breakpoints;

const width = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth ||
  0;

const height = () =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight ||
  0;

const xs = () => width() < breakpoints.sm;
const sm = () => width() >= breakpoints.sm;
const md = () => width() >= breakpoints.md;
const lg = () => width() >= breakpoints.lg;
const xl = () => width() >= breakpoints.xl;
const xl2 = () => width() >= breakpoints.xl2;

const forceLandscape = () => !sm() && window.innerHeight >= window.innerWidth;

function dimens() {
  return {
    width: width(),
    height: height(),
  };
}

export {
  breakpoints,
  dimens,
  xs,
  sm,
  md,
  lg,
  xl,
  xl2,
  width,
  height,
  forceLandscape,
};
