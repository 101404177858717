// this is auto generated, don't try and change it
const routes = [
  {importPath: './src/routes/index.tsx', path: 'index', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/index')},
  {importPath: './src/routes/Login.tsx', path: 'Login', importFunc: () => import(/* webpackMode: "eager" *//* webpackChunkName: "login" */'./routes/Login')},
  {importPath: './src/routes/Auth.tsx', path: 'Auth', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/Auth')},
  {importPath: './src/routes/404.tsx', path: '404', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/404')},
  {importPath: './src/routes/403.tsx', path: '403', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/403')},
  {importPath: './src/routes/App/index.tsx', path: 'App/index', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/App/index')},
  {importPath: './src/routes/App/_Layout.tsx', path: 'App/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/App/_Layout')},
  {importPath: './src/routes/App/RoomRequest.tsx', path: 'App/RoomRequest', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/App/RoomRequest')},
  {importPath: './src/routes/App/Account.tsx', path: 'App/Account', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/App/Account')},
  {importPath: './src/routes/App/View/index.tsx', path: 'App/View/index', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/App/View/index')},
  {importPath: './src/routes/App/View/_Layout.tsx', path: 'App/View/_Layout', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/App/View/_Layout')},
  {importPath: './src/routes/App/View/Camera/$entityId/index.tsx', path: 'App/View/Camera/:entityId/index', importFunc: () => import(/* webpackMode: "lazy" *//* webpackChunkName: "auth" */'./routes/App/View/Camera/$entityId/index')},
];

export default routes;
export type StrongRouteTypes =  | `/` | `/login` | `/auth` | `/404` | `/403` | `/app` | `/app/room-request` | `/app/account` | `/app/view` | `/app/view/camera/${string}`;
export const routeMap = {
	'index': () : StrongRouteTypes => `/`,
	'Login': () : StrongRouteTypes => `/login`,
	'Auth': () : StrongRouteTypes => `/auth`,
	'404': () : StrongRouteTypes => `/404`,
	'403': () : StrongRouteTypes => `/403`,
	'App/index': () : StrongRouteTypes => `/app`,
	'App/RoomRequest': () : StrongRouteTypes => `/app/room-request`,
	'App/Account': () : StrongRouteTypes => `/app/account`,
	'App/View/index': () : StrongRouteTypes => `/app/view`,
	'App/View/Camera/:entityId/index': (entityId: string) : StrongRouteTypes => `/app/view/camera/${entityId}`,
};
