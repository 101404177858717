import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useService } from 'envoc-request';
import { createStrictContext } from 'envoc-strict-context';
import { CameraListDto, WebViewCameraService } from 'api';
import { authSelectors } from 'shared/state';
import { useSessionContext } from 'features/Sessions';

const [ContextProvider, useCameraContext] = createStrictContext<{
  cameraList: CameraListDto | undefined;
  loading: boolean;
  refetch: Function;
  hasEventCamera: boolean;
}>({
  name: 'Camera',
});

function CameraContextProvider({ children }: React.PropsWithChildren) {
  const {
    resp,
    loading,
    submitRequest: refetch,
  } = useService(WebViewCameraService.getCameras, undefined, {
    autoExecute: false,
  });
  const { sessionStatus } = useSessionContext();

  const [storedCameraList, setStoredCameraList] = useState<CameraListDto>();

  const user = useSelector(authSelectors.getUser);

  useEffect(() => {
    if (resp?.result) {
      setStoredCameraList(resp.result);
    }
  }, [resp?.result]);

  useEffect(() => {
    if (user?.id) {
      refetch();
      return;
    }
    setStoredCameraList(undefined);
  }, [refetch, user?.id]);

  useEffect(() => {
    if (loading) {
      setStoredCameraList(undefined);
    }
  }, [loading]);

  const cameraList =
    sessionStatus === 'idle' || sessionStatus === 'ongoing'
      ? storedCameraList
      : undefined;

  const hasEventCamera = !!cameraList?.cameras?.some((c) => c.isEventCamera);

  const value = useMemo(() => {
    return { cameraList, loading, refetch, hasEventCamera };
  }, [cameraList, hasEventCamera, loading, refetch]);

  return <ContextProvider value={value}>{children}</ContextProvider>;
}

export { CameraContextProvider, useCameraContext };
