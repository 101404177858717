import { useEffect, useState } from 'react';

export function useLocalStorage(
  key: string
): [string | null, React.Dispatch<React.SetStateAction<string | null>>];
export function useLocalStorage<T extends number | string | boolean>(
  key: string,
  deserialize: (value: string) => T
): [T | null, React.Dispatch<React.SetStateAction<T | null>>];
export function useLocalStorage<T extends number | string | boolean>(
  key: string,
  deserialize?: (value: string) => T
): [
  T | string | null,
  React.Dispatch<React.SetStateAction<T | string | null>>,
] {
  const [initialKey] = useState(key);
  const [value, setValue] = useState<T | string | null>(() => {
    const val = localStorage.getItem(initialKey);
    return val === null ? val : deserialize?.(val) ?? val;
  });

  useEffect(() => {
    if (value !== null) {
      localStorage.setItem(initialKey, `${value}`);
    } else {
      localStorage.removeItem(initialKey);
    }
  }, [initialKey, value]);

  return [value, setValue];
}
