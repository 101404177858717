import type { ReactNode } from 'react';
import classNames from 'classnames';

export interface CardActionHeaderProps {
  className?: string;
  children?: ReactNode;
}
export default function CardActionHeader({
  children,
  className,
}: CardActionHeaderProps) {
  return (
    <div
      className={classNames(
        'flex flex-row flex-wrap items-baseline',
        className
      )}>
      {children}
    </div>
  );
}

CardActionHeader.Actions = Actions;
CardActionHeader.Title = Title;

export interface CardActionHeaderTitleProps {
  className?: string;
  children?: ReactNode;
  title: string;
}
function Title({ children, className, title }: CardActionHeaderTitleProps) {
  return (
    <header
      title={title}
      className={classNames('mb-3 mr-auto text-lg font-semibold ', className)}>
      {children || title}
    </header>
  );
}

export interface CardActionHeaderActionsProps {
  className?: string;
  children?: ReactNode;
}
function Actions({ children, className }: CardActionHeaderActionsProps) {
  return <div className={classNames('flex gap-1', className)}>{children}</div>;
}
